import {getDateISO} from "@/shared/dateFormat";
import moment from "moment/moment";

export interface HdDepartureListAnaliticsFiltersValues {
    id: string[];
    leadId: string[];
    perPage: number;
    createdAt: string[];
    uaeHandOverTs: string[];

}

export function getEmptyHdDepartureListAnaliticsFiltersValues() : HdDepartureListAnaliticsFiltersValues{
    return {
        id: [],
        leadId: [],
        perPage: 12,
        createdAt: [],
        uaeHandOverTs: [],
    }
}

export interface HdDepartureListAnaliticsFiltersPayload {
    lead_id?: string;
    page: number;
    per_page: number
    start_created_at?: string
    stop_created_at?: string
    start_uae_hand_over_ts?: string
    stop_uae_hand_over_ts?: string
    ids?: number[]
}

export function getEmptyHdDepartureListAnaliticsFiltersPayload() : HdDepartureListAnaliticsFiltersPayload {
    return {
        page: 1,
        per_page: 12,
    }
}

export function mapHdDepartureAnaliticsListFiltersValuesPayload(filtersValues: HdDepartureListAnaliticsFiltersValues, page: number) {
    let params: HdDepartureListAnaliticsFiltersPayload = {
        page: page,
        per_page: filtersValues.perPage
    }

    if (filtersValues.leadId.length) {
        params.lead_id = filtersValues.leadId?.join(",");
    }

    params.start_created_at = filtersValues.createdAt[0] ? moment(filtersValues.createdAt[0]).format('YYYY-MM-DD') : undefined;
    params.stop_created_at = filtersValues.createdAt[1] ? moment(filtersValues.createdAt[1]).format('YYYY-MM-DD') : undefined;

    params.start_uae_hand_over_ts = filtersValues.uaeHandOverTs[0] ? moment(filtersValues.uaeHandOverTs[0]).format('YYYY-MM-DD') : undefined;
    params.stop_uae_hand_over_ts = filtersValues.uaeHandOverTs[1] ? moment(filtersValues.uaeHandOverTs[1]).format('YYYY-MM-DD') : undefined;

    return params
}