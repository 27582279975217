import {getDateISO} from "@/shared/dateFormat";

export interface FiltersValuesDepartureItems {
    id: string[],
    leadId: string[],
    customerId: string[],
    purchaseDate: string[],
    refundDeadline: string[],
    storeStatus: string[],
    priority: string[],
    sumAed: string[],
    eurCost: string[],
    perPage: number,
}

export function getEmptyFiltersValuesDepartureItems (): FiltersValuesDepartureItems {
    return {
        id: [],
        leadId: [],
        customerId: [],
        purchaseDate: [],
        refundDeadline: [],
        storeStatus: [],
        priority: [],
        sumAed: ['', ''],
        eurCost: ['', ''],
        perPage: 12,
    }
}

export interface HdDepartureItemsFilterPayload {
    aed_min?: number,
    aed_max?: number,
    eur_min?: number,
    eur_max?: number,
    start_refund_deadline?: string,
    stop_refund_deadline?: string,
    start_buying_date?: string,
    stop_buying_date?: string,
    priority?: string,
    lead_id?: number,
    lead_status?: string,
    customer_id?: number,
    page: number,
    per_page: number
    ids?: number[]
}

export function mapHdDepartureItemsFilterPayload(filtersValues: FiltersValuesDepartureItems, page: number) {
    let params: {
        aed_min?: number,
        aed_max?: number,
        eur_min?: number,
        eur_max?: number,
        start_refund_deadline?: string,
        stop_refund_deadline?: string,
        start_buying_date?: string,
        stop_buying_date?: string,
        priority?: string,
        lead_id?: number,
        lead_status?: string,
        customer_id?: number,
        page: number,
        per_page: number
    } = {
        page: page,
        per_page: filtersValues.perPage,
    }

    if (filtersValues.leadId.length) {
        params.lead_id = filtersValues.leadId?.join(',') as any
    }

    if (filtersValues.customerId.length) {
        params.customer_id = filtersValues.customerId?.join(',') as any
    }

    if (filtersValues.purchaseDate.length) {
        params.start_buying_date = getDateISO(filtersValues.purchaseDate[0])
        params.stop_buying_date = getDateISO(filtersValues.purchaseDate[1])
    }

    if (filtersValues.refundDeadline.length) {
        params.start_refund_deadline = getDateISO(filtersValues.refundDeadline[0])
        params.stop_refund_deadline = getDateISO(filtersValues.refundDeadline[1])
    }

    if (filtersValues.storeStatus.length) {
        params.lead_status = filtersValues.storeStatus?.join(',')
    }

    if (filtersValues.priority.length) {
        params.priority = filtersValues.priority?.join(',')
    }

    if (filtersValues.sumAed.length) {
        params.aed_min = filtersValues.sumAed[0] as any
        params.aed_max = filtersValues.sumAed[1] as any
    }

    if (filtersValues.eurCost.length) {
        params.eur_min = filtersValues.eurCost[0] as any
        params.eur_max = filtersValues.eurCost[1] as any
    }

    return params;
}