import {TokenWrap} from "@/models/token_wrap";
import {Empty, getEmptyEmpty} from "@/models/empty";
import {ApiResponse, snakeCaseDeep} from "@/models/local_models/api_response";
// @ts-ignore
import * as process from "process";
import {AmoConfig} from "@/models/amo_config";
import {Role} from "@/models/role";
import {RoleTemplate} from "@/models/role_template";
import {EmiratesPostBooking} from "@/models/emirates_post_booking";
import {EmiratePostCountries} from "@/models/emirate_post_countries";
import {EmiratePostCity} from "@/models/emirate_post_city";
import {AmoLeadStatuses} from "@/models/amo_lead_statuses";
import {LeadStatus} from "@/models/lead_status";
import {Wishlist} from "@/models/wishlist";
import {User} from "@/models/user";
import {FilterItem, FilterItemRole} from "@/models/filter_item";
import {UserAdmin} from "@/models/user_admin";
import {CompanyContactItem} from "@/models/company_contact_item";
import {Payment} from "@/models/payment";
import {PaymentCreate} from "@/models/payment_create";
import {getEmptyPaymentFilters, PaymentFilters} from "@/models/payment_filters";
import {getEmptyPointStat3, Json, PointStat, PointStat2, PointStat3} from "@/models/point_stat";
import {getEmptyResultAnswerCreate, ResultAnswerCreate} from "@/models/ResultAnswerCreate";
import {PaymentAmoCustomField} from "@/models/payment_amo_custom_field";
import {PaymentsConfigUpdate} from "@/models/payments_config_update";
import {PaymentCustomField} from "@/models/payment_custom_field";
import {PaymentCustomServiceValue} from "@/models/payment_custom_service_value";
import {PaymentChannel} from "@/models/payment_channel";
import {PaymentChannelRequest} from "@/models/payment_channel_request";
import {PaymentChannelDefault2} from "@/models/payment_channel_default";
import {
    PaymentConfigAcquiring,
    PaymentConfigSbpDiscount,
    PaymentFormCreate,
    PaymentFormCreate2
} from "@/models/payment_form_create";
import {PaymentConfigCustomer} from "@/models/payment_config_customer";
import {ClientCalcCheck} from "@/models/client_calc_check";
import {ClientCalc} from "@/models/client_calc";
import {ClientCalcCheckPayload} from "@/models/client_calc_check_payload";
import {PaymentConfigPipelines} from "@/models/payment_config_piplines";
import {AppClient, AppClientFilters, getEmptyAppClient, getEmptyAppClientFilters} from "@/models/app_client";
import {LeadList} from "@/models/lead_list";
import {getEmptyLeadFullItem} from "@/models/lead_full_item";
import {LeadFull} from "@/models/lead_full";
import {AppClientEdit} from "@/models/app_client_edit";
import {Warehouse} from "@/models/warehouses";
import {getEmptyWarehouseFilter, WarehouseFilter} from "@/models/warehouses_filters";
import {getEmptyLeadParams, LeadParams} from "@/models/lead_params";
import {StoreProduct} from "@/models/store_product";
import {getEmptyStoreProductsParams, StoreProductsParams} from "@/models/store_products_params";
import {DepartureUpdateParams} from "@/models/departure_update_params";
import {DepartureFull, getEmptyDepartureFull} from "@/models/departure_full";
import {DepartureSmall} from "@/models/departure_small";
import {DepartureFilters, getEmptyDepartureFilters} from "@/models/departure_filters";
import {DepartureUpdateProblem} from "@/models/departure_update_problem";
import {DepartureLocalizate, getEmptyDepartureLocalizate} from "@/models/departure_localizate";
import {AppNotification} from "@/models/app_notification";
import {AppNotificationFilters, getEmptyAppNotificationFilters} from "@/models/app_notification_filters";
import {BonusType} from "@/models/bonus_type";
import {getEmptyPaymentLead, PaymentLead} from "@/models/payment_lead";
import {AppTransaction} from "@/models/app_transaction";
import {getEmptyStatsForm, StatsForm} from "@/models/stats_form";
import {TemplateCalcAdmin, TemplateCalcAdminCheck, TemplateCalcAdminCheckResult} from "@/models/template_calc_admin";
import {PaymentsForeignStat} from "@/models/payments_foreign_stat";
import {getEmptyPaymentsForeignStatParams, PaymentsForeignStatParams} from "@/models/payments_foreign_stat_params";
import {
    getEmptyPaymentStatColumnsFilters,
    PaymentStatColumns,
    PaymentStatColumnsFilters
} from "@/models/PaymentStatColumns";
import {getEmptyRussianPostBookings, RussianPostBookings} from "@/models/russian_post_bookings";
import {CourierHdDeparture, getEmptyCourierHdDeparture} from "@/models/courier_hd_departure";
import {
    getEmptyHdDepartureDeliveryServicesFilters,
    getEmptyHdDepartureItemsFilters,
    HdDepartureDeliveryServices2,
    HdDepartureDeliveryServicesFilters,
    HdDepartureItems,
    HdDepartureItemsFilters
} from "@/models/hd_departure_items";
import {DeliveryServices} from "@/models/delivery_services";
import {Airport} from "@/models/airport";
import {
    DeliveryServiceDeparture,
    DepartureHd,
    DepartureHdFilters,
    getEmptyDeliveryServiceDeparture,
    getEmptyDepartureHd,
    getEmptyDepartureHdFilters
} from "@/models/departure_hd";
import { HdAnalitics, HdAnaliticsFilters, getEmptyHdAnaliticsFilters } from "./models/hd_analitics";
import {getEmptyRefundFormData, RefundFormData, RefundFormDataUpdate} from "@/models/refund_form_data";
import {TemplateCalcOrderListClient} from "@/models/template_order_list_client";
import {HdDepartureItemsFilterPayload} from "@/views/HdDepartures/components/hdDepartureItemsHelper";
import {HdDepartureListFiltersValuesPayload} from "@/views/HdDepartures/components/HdDepartureListHelper";
import {HdDepartureListAnaliticsFiltersPayload} from "@/views/HdDepartures/components/HdDepartureListAnaliticsHelper";

export let basePath = localStorage.basePath ?? process.env.VUE_APP_API_URL;
export let amoCrmUrl = localStorage.amoCrmUrl ?? process.env.VUE_APP_AMO_CRM;
export let headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
};

export interface ClientListFilters {
    search?: string;
    status?: string;
    clientStatus?: string;
    startDate?: string;
    stopDate?: string;
    page?: number;
    emailVerified?: boolean;
    successLeadCounter?: number;
    successLeadCounterMin?: number;
    successLeadCounterMax?: number;
    totalBonusMin?: string;
    totalBonusMax?: string;
    perPage?: string;
}

export interface LeadsListFilters {
    search?: string;
    leadStatus?: string;
    amoStatus?: string;
    startDate?: string;
    stopDate?: string;
    direction?: string;
    departureStatus?: string;
    page?: number;
    limit?: number;
    customer?: number;
    hasDeparture?: boolean;
    hasTax?: boolean;
}

export interface WarehouseListFilters {
    directions?: string;
}

export interface StoreProductsListFilters {
    page?: number;
    storeStatus?: string[];
    direction?: string[];
    startDate?: string;
    stopDate?: string;
    search?: string;
}

export interface DepartureListFilters {
    page?: number;
    direction?: string[];
    search?: string;
    customer?: string;
    concierge?: string;
    logistician?: string;
    deliveryStatuses?: string[];
    departureStatuses?: string[];
    deliveryTypes?: string[];
    leadIds?: number[];
    startDate?: string;
    stopDate?: string;
}

function clearParams(params: { [x: string]: any }) {
    const params2: any = {};

    for (const paramsKey in params) {
        // @ts-ignore
        if (params[paramsKey] != '' && params[paramsKey].length) {
            // @ts-ignore
            params2[paramsKey] = params[paramsKey]
        }
    }

    return params2;
}

export const api = {
    async logout(): Promise<ApiResponse<Empty>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/user/logout";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Empty>(undefined, error);
        }
    },
    async loginAdmin({email, password,}: { email: string, password: string, }): Promise<ApiResponse<TokenWrap>> {
        const params: { [x: string]: any } = {};

        params["email"] = email
        params["password"] = password

        const path = basePath + "/api/user/login_admin";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                .then(r => r.json())
                .then(r => {
                    if (r.success === false) {
                        return new ApiResponse(undefined, r);
                    }
                    return new ApiResponse(r);
                });
        } catch (error) {
            return new ApiResponse<TokenWrap>(undefined, error);
        }
    },

    async updateConfig({
                           subdomain,
                           client_id,
                           client_secret,
                           code,
                           redirect_uri,
                       }: {
        subdomain: string,
        client_id: string,
        client_secret: string,
        code: string,
        redirect_uri: string,
    }): Promise<ApiResponse<any>> {
        const params: { [x: string]: any } = {};

        params["subdomain"] = subdomain
        params["client_id"] = client_id
        params["client_secret"] = client_secret
        params["code"] = code
        params["redirect_uri"] = redirect_uri

        const path = basePath + "/api/amo/config";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                .then(r => {
                    if (r.status !== 200) {
                        return new ApiResponse(undefined, r);
                    }
                    return new ApiResponse(r);
                });
        } catch (error) {
            return new ApiResponse<Empty>(undefined, error);
        }
    },

    async sendResetPassword({email,}: { email: string, }): Promise<ApiResponse<Empty>> {
        const params: { [x: string]: any } = {};

        params["email"] = email

        const path = basePath + "/api/user/send_reset_password";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                .then(r => r.json())
                .then(r => {
                    if (r.success === false) {
                        return new ApiResponse(undefined, r);
                    }
                    return new ApiResponse(r);
                });
        } catch (error) {
            return new ApiResponse<Empty>(undefined, error);
        }
    },


    async resetPassword({
                            token,
                            password,
                            password_confirmation,
                            email,
                        }: {
        token: string,
        password: string,
        password_confirmation: string,
        email: string,
    }): Promise<ApiResponse<Empty>> {
        const params: { [x: string]: any } = {};

        params["token"] = token
        params["password"] = password
        params["password_confirmation"] = password_confirmation
        params["email"] = email

        const path = basePath + "/api/user/reset_password";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                .then(r => r.json())
                .then(r => {
                    if (r.success === false) {
                        return new ApiResponse(undefined, r);
                    }
                    return new ApiResponse(r);
                });
        } catch (error) {
            return new ApiResponse<Empty>(undefined, error);
        }
    },

    async firstAuth(): Promise<ApiResponse<any>> {
        const params: { [x: string]: any } = {};
        const path = basePath + "/api/amo/first_auth";

        try {
            return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                .then(r => {
                    if (r.status !== 200) {
                        return new ApiResponse(undefined, r);
                    }
                    return new ApiResponse(r);
                });
        } catch (error) {
            return new ApiResponse<Empty>(undefined, error);
        }
    },

    async getPipelines(): Promise<ApiResponse<Empty>> {
        const params: { [x: string]: any } = {};
        const path = basePath + "/api/amo/pipelines" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => {
                    if (r.success === false) {
                        return new ApiResponse(undefined, r);
                    }
                    return new ApiResponse(r);
                });
        } catch (error) {
            return new ApiResponse<Empty>(undefined, error);
        }
    },

    async getAdminUserMe(): Promise<ApiResponse<UserAdmin>> {
        const params: { [x: string]: any } = {};

        const path = basePath + "/api/admin/users/me" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => {
                    if (r.success === false) {
                        return new ApiResponse(undefined, r);
                    }
                    return new ApiResponse(r);
                });
        } catch (error) {
            return new ApiResponse<UserAdmin>(undefined, error);
        }
    },

    async getConfigData(): Promise<ApiResponse<AmoConfig>> {
        const path = basePath + "/api/amo/config";

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => {
                    if (r.success === false) {
                        return new ApiResponse(undefined, r);
                    }
                    return new ApiResponse(r);
                });
        } catch (error) {
            return new ApiResponse<AmoConfig>(undefined, error);
        }
    },


    amo: {
        async firstSync({id,}: { id: number, }): Promise<ApiResponse<any>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/amo/pipelines/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(r => {
                        if (r.status != 200) {
                            return new ApiResponse(undefined, r);
                        }
                        return new ApiResponse(r);
                    });
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async syncAmoStatuses({id,}: { id: number, }): Promise<ApiResponse<any>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/amo/pipelines/{id}/statuses".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
        async syncAmoLead({id,}: { id: number, }): Promise<ApiResponse<any>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/amo/leads/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
    },

    roles: {
        async getRoles({
                           page,
                           roles,
                           search,
                           statuses,
                       }: {
            page: number,
            roles?: number[],
            search?: string,
            statuses: number[]
        }): Promise<ApiResponse<Role[]>> {
            const params: { [x: string]: any } = {};
            params['page'] = page;
            params['roles'] = roles ?? '';
            params['search'] = search ?? '';
            params['statuses'] = statuses ?? '';

            const path = basePath + "/api/admin/roles" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<Role[]>(undefined, error);
            }
        },

        async createRole({payload,}: { payload: Role, }): Promise<ApiResponse<Role>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/roles";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Role>(undefined, error);
            }
        },

        async updateRole({id, payload,}: { id: number, payload: Role, }): Promise<ApiResponse<Role>> {
            const params: { [x: string]: any } = payload;

            params["id"] = id

            const path = basePath + "/api/admin/roles/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Role>(undefined, error);
            }
        },

        async deleteRole({id,}: { id: number, }): Promise<ApiResponse<Role>> {
            const params: { [x: string]: any } = {};

            params["id"] = id

            const path = basePath + "/api/admin/roles/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Role>(undefined, error);
            }
        },

        async getRoleById({id}: { id: number }): Promise<ApiResponse<Role>> {
            const params: { [x: string]: any } = {};


            const path = basePath + "/api/admin/roles/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Role>(undefined, error);
            }
        },

        async getRolesFilter(): Promise<ApiResponse<FilterItemRole[]>> {
            const params: { [x: string]: any } = {};


            const path = basePath + "/api/admin/filters/roles" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<FilterItemRole[]>(undefined, error);
            }
        },

        async getActionsTemplate(): Promise<ApiResponse<RoleTemplate[]>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/roles/template" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<RoleTemplate[]>(undefined, error);
            }
        },
    },


    emiratesPost: {
        async getEmiratesPostAll({
                                     page,
                                     pageSize,
                                     statuses,
                                     stopDate,
                                     startDate,
                                 }: {
            page: number, pageSize?: number, statuses?: string[],
            startDate?: string,
            stopDate?: string,
        }): Promise<ApiResponse<EmiratesPostBooking[]>> {
            const params: { [x: string]: any } = {};

            params['page'] = page;
            params['per_page'] = pageSize ?? 12;
            params['status'] = Object.values((statuses ?? [])).join(',');
            params['start_date'] = startDate ?? '';
            params['stop_date'] = stopDate ?? '';

            const path = basePath + "/api/emirates_post" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<EmiratesPostBooking[]>(undefined, error);
            }
        },

        async getEmiratePostById({id,}: { id: number, }): Promise<ApiResponse<EmiratesPostBooking>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/emirates_post/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<EmiratesPostBooking>(undefined, error);
            }
        },

        async emiratesPostCreateBooking({payload,}: { payload: EmiratesPostBooking, }): Promise<ApiResponse<any>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/emirates_post";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<any>(undefined, error);
            }
        },

        async emiratesPostSendBooking({id,}: { id: number, }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = {};

            params["id"] = id

            const path = basePath + "/api/emirates_post/{id}/send_booking".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async updateEmiratePostBooking({
                                           id,
                                           payload,
                                       }: { id: number, payload: EmiratesPostBooking, }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/emirates_post/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async getCountries({search}: { search: string }): Promise<ApiResponse<EmiratePostCountries[]>> {
            const params: { [x: string]: any } = {search: search};


            const path = basePath + "/api/emirates_post/countries" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<EmiratePostCountries[]>(undefined, error);
            }
        },

        async getCities(idCountry: number, q: string): Promise<ApiResponse<EmiratePostCity[]>> {
            const params: { [x: string]: any } = {search: q};


            const path = basePath + "/api/emirates_post/countries/{id}/cities".replaceAll('{id}', idCountry.toString()) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<EmiratePostCity[]>(undefined, error);
            }
        },

        async getEmiratePostTemplate({id,}: { id: number, }): Promise<ApiResponse<EmiratesPostBooking>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/emirates_post/{id}/template".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<EmiratesPostBooking>(undefined, error);
            }
        },
    },

    statuses: {
        async getAmoLeadStatuses(): Promise<ApiResponse<AmoLeadStatuses[]>> {
            const params: { [x: string]: any } = {};


            const path = basePath + "/api/amo_lead_statuses" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<AmoLeadStatuses[]>(undefined, error);
            }
        },

        async createAmoLeadStatuses({payload,}: { payload: AmoLeadStatuses, }): Promise<ApiResponse<AmoLeadStatuses>> {
            const params: { [x: string]: any } = {};

            params["payload"] = payload

            const path = basePath + "/api/amo_lead_statuses";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<AmoLeadStatuses>(undefined, error);
            }
        },

        async updateAmoLeadStatuses({
                                        id,
                                        payload,
                                    }: {
            id: number,
            payload: AmoLeadStatuses,
        }): Promise<ApiResponse<AmoLeadStatuses>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/amo_lead_statuses/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<AmoLeadStatuses>(undefined, error);
            }
        },

        async deleteAmoLeadStatuses({id,}: { id: number, }): Promise<ApiResponse<AmoLeadStatuses>> {
            const params: { [x: string]: any } = {};

            params["id"] = id

            const path = basePath + "/api/amo_lead_statuses/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<AmoLeadStatuses>(undefined, error);
            }
        },

        async getLeadStatusesFull(): Promise<ApiResponse<LeadStatus[]>> {
            const params: { [x: string]: any } = {};


            const path = basePath + "/api/lead_statuses" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<LeadStatus[]>(undefined, error);
            }
        },

        async createLeadStatus({payload,}: { payload: LeadStatus, }): Promise<ApiResponse<LeadStatus>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/lead_statuses";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<LeadStatus>(undefined, error);
            }
        },

        async updateLeadStatus({id, payload,}: { id: number, payload: LeadStatus, }): Promise<ApiResponse<LeadStatus>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/lead_statuses/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<LeadStatus>(undefined, error);
            }
        },

        async deleteLeadStatus({id,}: { id: number, }): Promise<ApiResponse<LeadStatus>> {
            const params: { [x: string]: any } = {};

            params["id"] = id

            const path = basePath + "/api/lead_statuses/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                    .then(r => r.text())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<LeadStatus>(undefined, error);
            }
        },
    },

    async getWishlist({ids}: { ids: number[] }): Promise<ApiResponse<Wishlist[]>> {
        const params: { [x: string]: any } = {};
        params['ids'] = ids.join(',');

        const path = basePath + "/api/wishlist/share" + "?" + new URLSearchParams(params);

        try {
            return await fetch(path, {method: "GET", headers: headers})
                .then(r => r.json())
                .then(r => new ApiResponse(r));
        } catch (error) {
            return new ApiResponse<Wishlist[]>(undefined, error);
        }
    },

    users: {
        async getAdminUsers({
                                page,
                                roles,
                                status,
                                search,
                            }: {
            page: number,
            roles?: number[],
            status?: string[],
            search?: string
        }): Promise<ApiResponse<User[]>> {
            const params: { [x: string]: any } = {};
            params['page'] = page ?? '';
            params['roles'] = roles ?? '';
            params['status'] = status ?? '';
            params['search'] = search ?? '';


            const path = basePath + "/api/admin/users" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<User[]>(undefined, error);
            }
        },

        async createUser({payload,}: { payload: User }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/users";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async updateUser({payload, id,}: { payload: User, id: number, }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/users/:id".replace(':id', `${id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async deleteUser({id,}: { id: number }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = {};

            params["id"] = id

            const path = basePath + "/api/admin/users/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.status != 200) {
                            return new ApiResponse(json, json);
                        }
                        return new ApiResponse(json);
                    })
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async getUserById({id,}: { id: number, }): Promise<ApiResponse<User>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/users/:id".replace(':id', `${id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<User>(undefined, error);
            }
        },
    },

    companyContacts: {
        async getAdminCompanyContacts(): Promise<ApiResponse<CompanyContactItem[]>> {
            const params: { [x: string]: any } = {};


            const path = basePath + "/api/admin/contacts" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<CompanyContactItem[]>(undefined, error);
            }
        },

        async updateAdminCompanyContacts({payload,}: { payload: CompanyContactItem[] }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;


            const path = basePath + "/api/admin/contacts";

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
    },

    payments: {
        async getPaymentList({
                                 page,
                                 search,
                                 pageSize,
                                 statuses,
                                 concierge,
                                 stopDate,
                                 startDate,
                                 napr,
                             }: {
            page: number, search?: string, pageSize?: number, statuses?: string[], concierge?: number[],
            startDate?: string,
            stopDate?: string,
            napr: string,
        }): Promise<ApiResponse<Payment[]>> {
            const params: { [x: string]: any } = {};
            params['page'] = page;
            params['per_page'] = pageSize ?? 12;
            params['search'] = search ?? '';
            params['status'] = Object.values((statuses ?? [])).join(',');
            params['concierge'] = Object.values((concierge ?? [])).join(',');
            params['start_date'] = startDate ?? '';
            params['stop_date'] = stopDate ?? '';

            // console.log(params['concierge'])

            const path = basePath + "/api/admin/" + napr + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Payment[]>(undefined, error);
            }
        },

        async createPayment({
                                payload,
                                napr
                            }: { payload: PaymentCreate, napr: string, }): Promise<ApiResponse<ResultAnswerCreate>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/" + napr;

            try {
                return await fetch(path, {method: "POST", headers: headers, body: JSON.stringify(payload)})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(getEmptyResultAnswerCreate(), r[1]));
            } catch (error) {
                return new ApiResponse<ResultAnswerCreate>(getEmptyResultAnswerCreate(), error);
            }
        },
        async getPaymentById({payment_id, napr}: { payment_id: number, napr: string }): Promise<ApiResponse<Payment>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/" + napr + "/{payment_id}".replace('{payment_id}', `${payment_id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<Payment>(undefined, error);
            }
        },

        async getConciergesFilter(napr: string): Promise<ApiResponse<FilterItem[]>> {
            const params: { [x: string]: any } = {};


            const path = basePath + "/api/admin/" + napr + "/filters/concierges" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<FilterItem[]>(undefined, error);
            }
        },

        async getPaymentFilters(napr: string): Promise<ApiResponse<PaymentFilters>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/" + napr + "/filters" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<PaymentFilters>(undefined, error);
            }
        },

        async changePayment({
                                id,
                                type,
                                conciergeId,
                                napr,
                            }: {
            id: number,
            type: string,
            conciergeId: number,
            napr: string
        }): Promise<ApiResponse<undefined>> {
            const params: { [x: string]: any } = {};

            params["id"] = id
            params["type"] = type
            params["concierge_id"] = conciergeId

            const path = basePath + "/api/admin/" + napr + "/{id}/{type}".replace('{id}', `${id}`).replace('{type}', `${type}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<undefined>(undefined, error);
            }
        },

        async deletePayment({payment_id, napr}: { payment_id: number, napr: string }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = {};

            params["payment_id"] = payment_id

            const path = basePath + "/api/admin/" + napr + "/{payment_id}".replace('{payment_id}', `${payment_id}`);

            try {
                return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async paymentRefund({
                                id,
                                partial,
                                amount,
                                napr,
                                all_bonuses,
                                amount_as_bonuses,
                                amount_bonuses,
                                full_bonuses,
                                full_rub,
                            }: {
            id: number,
            partial: boolean,
            all_bonuses: boolean,
            full_rub: boolean,
            full_bonuses: boolean,
            amount: number,
            amount_bonuses: number,
            amount_as_bonuses: number,
            napr: string
        }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = {};

            params["id"] = id
            params["partial"] = partial
            params["amount"] = amount
            if (amount_bonuses) {
                params["amount_bonuses"] = amount_bonuses
            }
            if (amount_as_bonuses) {
                params["amount_as_bonuses"] = amount_as_bonuses
            }
            params["all_bonuses"] = all_bonuses
            params["amount_as_bonuses"] = amount_as_bonuses
            params["full_bonuses"] = full_bonuses
            params["full_rub"] = full_rub

            const path = basePath + "/api/admin/" + napr + "/refund/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async paymentRefundBonuses({
                                       id,
                                       partial,
                                       amount,
                                       napr,
                                   }: {
            id: number,
            partial: boolean,
            amount: number,
            napr: string
        }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = {};

            params["id"] = id
            params["partial"] = partial
            params["amount"] = amount

            const path = basePath + "/api/admin/" + napr + "/refund/{id}/bonuses".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async getStatistics1({range, napr}: { range: number, napr: string }): Promise<ApiResponse<PointStat[]>> {
            const params: { [x: string]: any } = {};
            params['range'] = range;

            const path = basePath + "/api/admin/" + napr + "/graph/sum" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PointStat[]>(undefined, error);
            }
        },

        async getStatistics2({range, napr}: { range: number, napr: string }): Promise<ApiResponse<PointStat[]>> {
            const params: { [x: string]: any } = {};
            params['range'] = range;

            const path = basePath + "/api/admin/" + napr + "/graph/average" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PointStat[]>(undefined, error);
            }
        },

        async getPaymentLead(amoId: number): Promise<ApiResponse<PaymentLead>> {
            const path = basePath + `/api/admin/payments/lead/${amoId}`;

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentLead>(getEmptyPaymentLead(), error);
            }

        },
    },

    paymentStat: {
        async getStatisticsFilters(): Promise<ApiResponse<StatsForm>> {
            const path = basePath + "/api/admin/analytics/payment/filters";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<StatsForm>(getEmptyStatsForm(), r[1]));
            } catch (error) {
                return new ApiResponse<StatsForm>(getEmptyStatsForm(), error);
            }
        },
        async getStatisticsCommodityCirculation(params: {
            currency: string,
            company: string,
            concierge_ids?: string,
            direction?: string,
            start_date?: string,
            stop_date?: string
        }): Promise<ApiResponse<PointStat2[]>> {
            const params2: any = {};

            for (const paramsKey in params) {
                // @ts-ignore
                if (params[paramsKey] != '' && params[paramsKey].length) {
                    // @ts-ignore
                    params2[paramsKey] = params[paramsKey]
                }
            }

            const path = basePath + "/api/admin/analytics/payment/commodity_circulation" + "?" + new URLSearchParams(params2);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PointStat2[]>([], r[1]));
            } catch (error) {
                return new ApiResponse<PointStat2[]>([], error);
            }
        },
        async getStatisticsPie(params: {
            company: string,
            start_date?: string,
            stop_date?: string
        }): Promise<ApiResponse<PointStat3[]>> {
            const params2: any = {};

            for (const paramsKey in params) {
                // @ts-ignore
                if (params[paramsKey] != '' && params[paramsKey].length) {
                    // @ts-ignore
                    params2[paramsKey] = params[paramsKey]
                }
            }

            const path = basePath + "/api/admin/analytics/payment/payment_type_pie" + "?" + new URLSearchParams(params2);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PointStat3[]>([], r[1]));
            } catch (error) {
                return new ApiResponse<PointStat3[]>([], error);
            }
        },
        async getStatisticsConciergeSum(params: {
            currency: string,
            company: string,
            concierge_ids?: string,
            direction?: string,
            start_date?: string,
            stop_date?: string
            services?: string
        }): Promise<ApiResponse<PointStat3>> {
            const params2: any = {};

            for (const paramsKey in params) {
                // @ts-ignore
                if (params[paramsKey] != '' && params[paramsKey].length) {
                    // @ts-ignore
                    params2[paramsKey] = params[paramsKey]
                }
            }

            const path = basePath + "/api/admin/analytics/payment/concierge_sum" + "?" + new URLSearchParams(params2);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PointStat3>(getEmptyPointStat3(), r[1]));
            } catch (error) {
                return new ApiResponse<PointStat3>(getEmptyPointStat3(), error);
            }
        },
        async getStatisticsCountLeads(params: {
            concierge_ids?: string,
            start_date?: string,
            stop_date?: string
            company?: string
            currency?: string
        }): Promise<ApiResponse<PointStat3>> {
            const params2: any = {};

            for (const paramsKey in params) {
                // @ts-ignore
                if (params[paramsKey] != '' && params[paramsKey].length) {
                    // @ts-ignore
                    params2[paramsKey] = params[paramsKey]
                }
            }

            const path = basePath + "/api/admin/analytics/payment/counter" + "?" + new URLSearchParams(params2);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PointStat3>(getEmptyPointStat3(), r[1]));
            } catch (error) {
                return new ApiResponse<PointStat3>(getEmptyPointStat3(), error);
            }
        },
        async getStatisticsMainProps(params: {
            company?: string
            concierge_ids?: string,
            direction?: string
            start_date?: string,
            stop_date?: string
        }): Promise<ApiResponse<{ [x: string]: number }>> {
            const path = basePath + "/api/admin/analytics/payment/table" + "?" + new URLSearchParams(clearParams(params));

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<{
                        [x: string]: number
                    }>({}, r[1]));
            } catch (error) {
                return new ApiResponse<{ [x: string]: number }>({}, error);
            }
        },
        async getStatisticsCommission(params: {
            company?: string
            concierge_ids?: string,
            direction?: string
            start_date?: string,
            stop_date?: string
        }): Promise<ApiResponse<PointStat3>> {
            const path = basePath + "/api/admin/analytics/payment/commission" + "?" + new URLSearchParams(clearParams(params));

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PointStat3>(getEmptyPointStat3(), r[1]));
            } catch (error) {
                return new ApiResponse<PointStat3>(getEmptyPointStat3(), error);
            }
        },
        async getStatisticsAvgNMedian(params: {
            company?: string
            concierge_ids?: string,
            currency?: string,
            services?: string,
            direction?: string
            start_date?: string,
            stop_date?: string
        }): Promise<ApiResponse<PointStat3>> {
            const path = basePath + "/api/admin/analytics/payment/AvgNMedian" + "?" + new URLSearchParams(clearParams(params));

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PointStat3>(getEmptyPointStat3(), r[1]));
            } catch (error) {
                return new ApiResponse<PointStat3>(getEmptyPointStat3(), error);
            }
        },
        async getStatisticsCompareByDates(params: {
            company?: string
            concierge_ids?: string,
            currency?: string,
            services?: string,
            direction?: string
            start_date?: string,
            stop_date?: string,
            truncate_parameter?: string,
        }): Promise<ApiResponse<PointStat3[]>> {
            const path = basePath + "/api/admin/analytics/payment/currencyRelation" + "?" + new URLSearchParams(clearParams(params));

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PointStat3[]>(getEmptyPointStat3(), r[1]));
            } catch (error) {
                return new ApiResponse<PointStat3[]>(getEmptyPointStat3(), error);
            }
        },
        async getStatisticsCurrencies(): Promise<ApiResponse<Json>> {
            const path = basePath + "/api/admin/analytics/payment/exchange";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<Json>({}, r[1]));
            } catch (error) {
                return new ApiResponse<Json>({}, error);
            }
        },
    },

    paymentStatColumns: {
        async getFilters(params: { company?: string }): Promise<ApiResponse<PaymentStatColumnsFilters>> {
            const path = basePath + "/api/admin/analytics/config/filters" + "?" + new URLSearchParams(clearParams(params));

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PaymentStatColumnsFilters>(getEmptyPaymentStatColumnsFilters(), r[1]));
            } catch (error) {
                return new ApiResponse<PaymentStatColumnsFilters>(getEmptyPaymentStatColumnsFilters(), error);
            }
        },
        async getItems(params: { search?: string, company?: string }): Promise<ApiResponse<PaymentStatColumns[]>> {
            const path = basePath + "/api/admin/analytics/config" + "?" + new URLSearchParams(clearParams(params));

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PaymentStatColumns[]>([], r[1]));
            } catch (error) {
                return new ApiResponse<PaymentStatColumns[]>([], error);
            }
        },

        async createItem({payload}: { payload: PaymentStatColumns }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + `/api/admin/analytics/config/`;

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<Empty>({}, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async updateItem({payload, id}: { id: number, payload: PaymentStatColumns }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;


            const path = basePath + `/api/admin/analytics/config/${id}`;

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<Empty>({}, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async deleteItem(id: number): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/analytics/config/${id}`;

            try {
                return await fetch(path, {method: "DELETE", headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

    },

    paymentServices: {
        async getAmoCustomFields(): Promise<ApiResponse<PaymentAmoCustomField[]>> {
            const params: { [x: string]: any } = {};


            const path = basePath + "/api/admin/get_amo_custom_fields" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentAmoCustomField[]>(undefined, error);
            }
        },
        async getPaymentServices(company: 'all' | 'brandelivery' | 'expresso' = 'all'): Promise<ApiResponse<PaymentCustomField[]>> {
            const params: { [x: string]: any } = {"company": company};


            const path = basePath + "/api/admin/amo_services" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentCustomField[]>(undefined, error);
            }
        },


        async updatePaymentServices({payload,}: { payload: PaymentsConfigUpdate }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;


            const path = basePath + "/api/admin/amo_services";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async getPaymentServiceValue({
                                         id,
                                         lead_id,
                                     }: {
            id: number,
            lead_id: number,
        }): Promise<ApiResponse<PaymentCustomServiceValue>> {
            const params: { [x: string]: any } = {"lead_id": lead_id};


            const path = basePath + "/api/admin/payments/service_info/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(r => r.json())
                    .then(r => new ApiResponse(r));
            } catch (error) {
                return new ApiResponse<PaymentCustomServiceValue>(undefined, error);
            }
        },

    },

    paymentChannel: {
        async getPaymentChannels(): Promise<ApiResponse<PaymentChannel[]>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/payments_channels" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentChannel[]>([], error);
            }
        },

        /** Не используется, можно удалить */
        async updatePaymentChannels({payload,}: { payload: PaymentChannelRequest }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/payments_channels";

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async getPaymentChannelFilters({entity}: { entity: boolean, }): Promise<ApiResponse<PaymentFilters>> {
            const params: { [x: string]: any } = {"entity": entity};

            const path = basePath + "/api/admin/payments_channels/entity/filters" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentFilters>(getEmptyPaymentFilters(), error);
            }
        },
    },

    channelDefault: {
        async updatePaymentChannelsDefault({
                                               company,
                                               gateway,
                                               type,
                                           }: {
            company: string,
            gateway: string,
            type: string,
        }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = {};

            params["company"] = company
            params["gateway"] = gateway
            params["type"] = type

            const path = basePath + "/api/admin/payments_channels";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async getDefaultPaymentChannels(): Promise<ApiResponse<PaymentChannelDefault2[]>> {
            const path = basePath + "/api/admin/payments_channels/channels";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentChannelDefault2[]>(undefined, error);
            }
        },

        async updateDefaultPaymentChannels({company, option, id,}: {
            company: string,
            option: string,
            id: number,
        }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = {};

            params["company"] = company
            params["option"] = option
            params["id"] = id

            const path = basePath + "/api/admin/payments_channels";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

    },

    paymentChannelForm: {
        async getPaymentFormCreate({gateway, type,}: {
            gateway: string,
            type: string,
        }): Promise<ApiResponse<PaymentFormCreate>> {
            const params: { [x: string]: any } = {
                "gateway": gateway,
                "type": type,
            };

            const path = basePath + "/api/admin/payments_channels/entity" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PaymentFormCreate>(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentFormCreate>(undefined, error);
            }
        },

        async createPaymentFormEntity({payload,}: { payload: PaymentFormCreate2, }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/payments_channels/entity";

            try {
                return await fetch(path, {method: "POST", headers: headers, body: JSON.stringify(payload)})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<Empty>(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async updatePaymentFormEntity({id, payload,}: {
            id: number,
            payload: PaymentFormCreate2,
        }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            params["id"] = id

            const path = basePath + "/api/admin/payments_channels/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<Empty>(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async deletePaymentFormEntity({id,}: { id: number, }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = {};

            params["id"] = id

            const path = basePath + "/api/admin/payments_channels/entity/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<Empty>(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async checkPaymentEntity({payload,}: { payload: PaymentChannelRequest }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/payments_channels/check";

            try {
                return await fetch(path, {method: "POST", headers: headers, body: JSON.stringify(payload)})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async getAcquiringValues(): Promise<ApiResponse<PaymentConfigAcquiring>> {
            const path = basePath + "/api/admin/payment_config/acquiring"

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PaymentConfigAcquiring>(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentConfigAcquiring>(undefined, error);
            }
        },
        async updateAcquiringValues(payload: PaymentConfigAcquiring): Promise<ApiResponse<Empty>> {
            const path = basePath + "/api/admin/payment_config/acquiring"

            try {
                return await fetch(path, {
                    method: "PUT",
                    headers: headers,
                    body: JSON.stringify(snakeCaseDeep(payload))
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<Empty>(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
        async getSbpDiscountValues(): Promise<ApiResponse<PaymentConfigSbpDiscount>> {
            const path = basePath + "/api/admin/payment_config/sbp_discount"

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<PaymentConfigSbpDiscount>(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentConfigSbpDiscount>(undefined, error);
            }
        },
        async updateSbpDiscountValues(payload: PaymentConfigSbpDiscount): Promise<ApiResponse<Empty>> {
            const path = basePath + "/api/admin/payment_config/sbp_discount"

            try {
                return await fetch(path, {
                    method: "PUT",
                    headers: headers,
                    body: JSON.stringify(snakeCaseDeep(payload))
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse<Empty>(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
    },

    paymentConfigCustomer: {
        async getPaymentConfigCustomer(): Promise<ApiResponse<PaymentConfigCustomer>> {
            const params: { [x: string]: any } = {};


            const path = basePath + "/api/admin/payment_config/template";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentConfigCustomer>(undefined, error);
            }
        },

        async updatePaymentConfigCustomer({payload,}: { payload: PaymentConfigCustomer }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/payment_config/template";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
    },

    paymentTemplateCalc: {
        async getPaymentTemplateCalc(): Promise<ApiResponse<ClientCalc>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/payment_config/template/client_calculation" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<ClientCalc>(undefined, error);
            }
        },

        async updatePaymentTemplateCalc({payload,}: { payload: ClientCalc }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/payment_config/template/client_calculation";

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async checkPaymentTemplateCalc({payload,}: {
            payload: ClientCalcCheckPayload
        }): Promise<ApiResponse<ClientCalcCheck>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/payment_config/template/client_calculation";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.text()]))
                    .then(r => {
                        let resPayload = r[1];
                        try {
                            const d = JSON.parse(resPayload as any);
                            if (d.success == false) {
                                resPayload = d
                            }
                        } catch (e) {

                        }
                        return [200, 201].includes((r[0] as Response).status) ? new ApiResponse(resPayload) : new ApiResponse(undefined, resPayload);
                    });
            } catch (error) {
                return new ApiResponse<ClientCalcCheck>(undefined, error);
            }
        },
    },

    paymentTemplateCalcAdmin: {
        async getPaymentTemplateCalc(): Promise<ApiResponse<TemplateCalcAdmin>> {
            const path = basePath + "/api/admin/payment_config/concierge_template";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<TemplateCalcAdmin>(undefined, error);
            }
        },
        async updatePaymentTemplateCalc(payload: TemplateCalcAdmin): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/payment_config/concierge_template";

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async checkPaymentTemplateCalc(payload: TemplateCalcAdminCheck): Promise<ApiResponse<TemplateCalcAdminCheckResult>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/payment_config/concierge_template";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<TemplateCalcAdminCheckResult>(undefined, error);
            }
        },
    },

    paymentTemplateOrderListClient: {
        async getPaymentTemplateOrderList(): Promise<ApiResponse<TemplateCalcOrderListClient>> {
            const path = basePath + "/api/admin/payment_config/order_list_template";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<TemplateCalcOrderListClient>(undefined, error);
            }
        },
        async updatePaymentTemplateOrderList(payload: TemplateCalcOrderListClient): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/payment_config/order_list_template";

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async checkPaymentTemplateOrderList(payload: TemplateCalcOrderListClient): Promise<ApiResponse<Blob>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/payment_config/order_list_template";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.blob()]))
                    .then(r => [200, 201].includes((r[0] as any).status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Blob>(undefined, error);
            }
        },
    },

    paymentConfigPipelines: {
        async getPaymentConfigPipelines(): Promise<ApiResponse<PaymentConfigPipelines>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/payment_config/amo_hooks" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentConfigPipelines>(undefined, error);
            }
        },

        async updatePaymentConfigPipelines({payload,}: {
            payload: PaymentConfigPipelines
        }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/payment_config/amo_hooks";

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
    },

    paymentConfigUsers: {
        async getPaymentConfigUsers(): Promise<ApiResponse<UserAdmin[]>> {
            const path = basePath + "/api/admin/payment_config/amo_hooks/default_amo_concierge/filter";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<UserAdmin[]>([], error);
            }
        },

        async getPaymentConfigUserDefault(): Promise<ApiResponse<UserAdmin>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/payment_config/amo_hooks/default_amo_concierge" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<UserAdmin>(undefined, error);
            }
        },

        async updatePaymentConfigUserDefault(params: { id: number }): Promise<ApiResponse<Empty>> {
            const path = basePath + "/api/admin/payment_config/amo_hooks/default_amo_concierge";

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
    },

    paymentForeignBankStat: {
        async getPaymentsForeignStat({page}: { page: number }): Promise<ApiResponse<PaymentsForeignStat[]>> {
            const params: { [x: string]: any } = {};
            params['page'] = page;

            const path = basePath + "/api/admin/payments-foreign-stat" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentsForeignStat[]>(undefined, error);
            }
        },
        async getPaymentsForeignStatParams(): Promise<ApiResponse<PaymentsForeignStatParams>> {
            const path = basePath + "/api/admin/payments-foreign-stat/params";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentsForeignStatParams>(getEmptyPaymentsForeignStatParams(), error);
            }
        },
        async importPaymentsForeignStat(file: string): Promise<ApiResponse<PaymentsForeignStat>> {
            const path = basePath + "/api/admin/payments-foreign-stat/upload";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify({file}), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentsForeignStat>(undefined, error);
            }
        },
        async createPaymentsForeignStat({payload,}: {
            payload: PaymentsForeignStat,
        }): Promise<ApiResponse<PaymentsForeignStat>> {
            const params: { [x: string]: any } = payload;


            const path = basePath + "/api/admin/payments-foreign-stat";

            try {
                return await fetch(path, {
                    method: "POST",
                    body: JSON.stringify(snakeCaseDeep(params)),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentsForeignStat>(undefined, error);
            }
        },
        async updatePaymentsForeignStat({id, payload,}: {
            id: number,
            payload: PaymentsForeignStat,
        }): Promise<ApiResponse<PaymentsForeignStat>> {
            const params: { [x: string]: any } = payload;

            params["id"] = id

            const path = basePath + "/api/admin/payments-foreign-stat/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(snakeCaseDeep(params)), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentsForeignStat>(undefined, error);
            }
        },
        async deletePaymentsForeignStat({id,}: { id: number, }): Promise<ApiResponse<PaymentsForeignStat>> {
            const params: { [x: string]: any } = {};

            params["id"] = id

            const path = basePath + "/api/admin/payments-foreign-stat/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<PaymentsForeignStat>(undefined, error);
            }
        },

    },

    client: {
        async filters(): Promise<ApiResponse<AppClientFilters>> {

            const path = basePath + "/api/admin/clients/filters"

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<AppClientFilters>(getEmptyAppClientFilters(), error);
            }
        },
        async getItems({
                           search,
                           emailVerified,
                           status,
                           stopDate,
                           startDate,
                           successLeadCounter,
                           successLeadCounterMin,
                           successLeadCounterMax,
                           page,
                           clientStatus,
                           totalBonusMin,
                           totalBonusMax,
                           perPage,
                       }: ClientListFilters): Promise<ApiResponse<AppClient[]>> {

            const params: { [x: string]: any } = {};
            if (search) params['search'] = search;
            if (emailVerified) params['emailVerified'] = emailVerified;
            if (status) params['status'] = status;
            if (stopDate) params['stopDate'] = stopDate;
            if (startDate) params['startDate'] = startDate;
            if (page) params['page'] = page;
            if (successLeadCounter) params['success_lead_counter'] = successLeadCounter;
            if (successLeadCounterMin) params['min_success_lead_counter'] = successLeadCounterMin;
            if (successLeadCounterMax) params['max_success_lead_counter'] = successLeadCounterMax;
            if (clientStatus) params['client_status'] = clientStatus;
            if (totalBonusMin) params['total_bonus_min'] = totalBonusMin;
            if (totalBonusMax) params['total_bonus_max'] = totalBonusMax;
            if (perPage) params['per_page'] = perPage;

            const path = basePath + "/api/admin/clients" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<AppClient[]>([], error);
            }
        },
        async getItem({id}: { id: number }): Promise<ApiResponse<AppClient>> {

            const path = basePath + `/api/admin/clients/${id}`;

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<AppClient>(getEmptyAppClient(), error);
            }
        },
        async update(params: { id: number, item: AppClientEdit }): Promise<ApiResponse<Empty>> {
            const path = basePath + "/api/admin/clients/" + params.id;

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params.item), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

    },

    leads: {
        async getItems({
                           search,
                           leadStatus,
                           amoStatus,
                           customer,
                           stopDate,
                           startDate,
                           departureStatus,
                           direction,
                           page,
                           limit,
                           hasDeparture,
                           hasTax,
                       }: LeadsListFilters): Promise<ApiResponse<LeadList[]>> {

            const params: { [x: string]: any } = {};
            if (search) params['search'] = search;
            if (leadStatus) params['lead_status'] = leadStatus;
            if (amoStatus) params['amo_status'] = amoStatus;
            if (stopDate) params['stop_date'] = stopDate;
            if (startDate) params['start_date'] = startDate;
            if (page) params['page'] = page;
            if (departureStatus) params['departure_status'] = departureStatus;
            if (direction) params['direction'] = direction;
            if (customer) params['customer'] = customer;
            if (limit) params['limit'] = limit;
            if (limit) params['per_page'] = limit;
            if (hasDeparture != undefined) params['has_departure'] = hasDeparture;
            if (hasTax != undefined) params['has_tax'] = hasTax;

            const path = basePath + "/api/admin/leads" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<LeadList[]>([], error);
            }
        },
        async getItem({id}: { id: number }): Promise<ApiResponse<LeadFull>> {

            const path = basePath + `/api/admin/leads/${id}`;

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<LeadFull>(getEmptyLeadFullItem(), error);
            }
        },

        // Функция получения фильтров
        async getLeadsFilter(): Promise<ApiResponse<LeadParams>> {
            const path = basePath + "/api/admin/leads/filters"

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<LeadParams>(getEmptyLeadParams(), error);
            }
        },

        async updateLead(params: {
            id: number,
            deal_note: string,
            warehouse_note: string
        }): Promise<ApiResponse<Empty>> {
            const path = basePath + "/api/admin/leads/" + params.id;

            try {
                return await fetch(path, {
                    method: "PUT", body: JSON.stringify({
                        "deal_note": params.deal_note,
                        "warehouse_note": params.warehouse_note,
                    }), headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
        async updateLeadTaxHidden(params: {
            id: number,
            has_tax_hidden: boolean,
        }): Promise<ApiResponse<Empty>> {
            const path = basePath + "/api/admin/leads/" + params.id;

            try {
                return await fetch(path, {
                    method: "PUT", body: JSON.stringify({
                        "has_tax_hidden": params.has_tax_hidden,
                    }), headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

    },

    warehouses: {
        // Функция создания склада (createWarehouse)
        async createWarehouse(warehouse: Warehouse): Promise<ApiResponse<Empty>> {
            const path = basePath + "/api/admin/logistic/warehouses";

            try {
                return await fetch(path, {
                    method: "POST",
                    body: JSON.stringify(warehouse),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        // Функция удаления склада (deleteWarehouse)
        async deleteWarehouse(id: number): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/logistic/warehouses/${id}`;

            try {
                return await fetch(path, {
                    method: "DELETE",
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        // Функция обновления склада (updateWarehouse)
        async updateWarehouse(id: number, warehouse: Warehouse): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/logistic/warehouses/${id}`;

            try {
                return await fetch(path, {
                    method: "PUT",
                    body: JSON.stringify(warehouse),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        // Функция получения списка складов (getWarehouses)
        async getWarehouses(filters: WarehouseListFilters): Promise<ApiResponse<Warehouse[]>> {
            const params: { [x: string]: any } = {};
            if (filters.directions) params['directions'] = filters.directions;

            const path = basePath + "/api/admin/logistic/warehouses" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Warehouse[]>([], error);
            }
        },

        // Функция получения фильтров
        async getWarehouseFilter(): Promise<ApiResponse<WarehouseFilter>> {
            const path = basePath + "/api/admin/logistic/warehouses/filters"

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<WarehouseFilter>(getEmptyWarehouseFilter(), error);
            }
        },

    },

    russianPost: {
        // Функция получения списка броней (getRussianPosts)
        async getRussianPosts(filters: { page: number }): Promise<ApiResponse<RussianPostBookings[]>> {
            const params: { [x: string]: any } = {};
            if (filters.page) params['page'] = filters.page;

            const path = basePath + "/api/admin/russian_post" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<RussianPostBookings[]>([], error);
            }
        },

        // Функция получения брони по id (getRussianPostById)
        async getRussianPostById(id: number): Promise<ApiResponse<RussianPostBookings>> {
            const path = basePath + "/api/admin/russian_post/" + id;

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<RussianPostBookings>(getEmptyRussianPostBookings(), error);
            }
        },

        // Функция создания брони (createRussianPost)
        async createRussianPost(id: number): Promise<ApiResponse<{ id: number }>> {
            const path = basePath + "/api/admin/russian_post/manifest/" + id;

            try {
                return await fetch(path, {
                    method: "POST",
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<{ id: number }>({id: 0}, error);
            }
        },

        // Функция удаления брони (deleteRussianPost)
        async deleteRussianPost(id: number): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/russian_post/${id}`;

            try {
                return await fetch(path, {
                    method: "DELETE",
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        // Функция обновления брони (updateRussianPost)
        async updateRussianPost(id: number, russianPostBookings: RussianPostBookings): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/russian_post/${id}`;

            try {
                return await fetch(path, {
                    method: "PUT",
                    body: JSON.stringify(russianPostBookings),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        // Функция отправки манифеста (sendRussianPost)
        async sendRussianPost(id: number): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/russian_post/manifest/${id}/send`;

            try {
                return await fetch(path, {
                    method: "POST",
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        // // Функция получения фильтров
        // async getRussianPostFilter(): Promise<ApiResponse<WarehouseFilter>> {
        //     const path = basePath + "/api/admin/logistic/warehouses/filters"
        //
        //     try {
        //         return await fetch(path, {method: "GET", headers: headers})
        //             .then(async r => ([r, await r.json()]))
        //             .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
        //     } catch (error) {
        //         return new ApiResponse<WarehouseFilter>(getEmptyWarehouseFilter(), error);
        //     }
        // },

    },

    storeProduct: {
        /** Функция получения списка товаров (getProducts) */
        async getProducts(filters: StoreProductsListFilters): Promise<ApiResponse<StoreProduct[]>> {
            const params: { [x: string]: any } = {};
            if (filters.page) params['page'] = filters.page;
            if (filters.storeStatus?.length) params['store_status'] = filters.storeStatus.join(',');
            if (filters.search) params['search'] = filters.search
            if (filters.startDate) params['start_date'] = filters.startDate
            if (filters.stopDate) params['stop_date'] = filters.stopDate
            if (filters.direction) params['direction'] = filters.direction

            const path = basePath + "/api/admin/logistic/products" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<StoreProduct[]>([], error);
            }
        },

        /** Функция получения фильтров */
        async getFilter(): Promise<ApiResponse<StoreProductsParams>> {
            const path = basePath + "/api/admin/logistic/products/filters"

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<StoreProductsParams>(getEmptyStoreProductsParams(), error);
            }
        },

        /** Смена статуса предмета */
        async updateStatus(id: number, params: {
            status: string | undefined,
            trackNumberIm: string,
            numberOrderIm: string,
            hsCode?: string,
            checkResult?: string,
            checkNote?: string
        }): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/logistic/products/${id}`;

            const payload: any = {
                status: params.status,
                track_number_im: params.trackNumberIm,
                number_order_im: params.numberOrderIm,
            };

            payload.hs_code = params.hsCode
            payload.check_result = params.checkResult
            payload.check_note = params.checkNote

            try {
                return await fetch(path, {
                    method: "PUT",
                    body: JSON.stringify(payload),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
        async createUnamed(props: {
            status: string,
            track_number_im: string,
            number_order_im: string,
            note: string,
            images: string[]
        }): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/logistic/products/unnamed`;

            try {
                return await fetch(path, {
                    method: "POST",
                    body: JSON.stringify(props),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async deleteUnamed(id: number): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/logistic/products/unnamed/${id}`;

            try {
                return await fetch(path, {
                    method: "DELETE",
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        async connectUnamed(idProduct: number, idUnnamed: number): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/logistic/products/unnamed/${idUnnamed}`;

            try {
                return await fetch(path, {
                    method: "PUT",
                    headers: headers,
                    body: JSON.stringify({target_product_id: idProduct})
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },


    },

    departure: {
        /** Функция получения списка отправлений (getDeparture) */
        async getDepartures(filters: DepartureListFilters): Promise<ApiResponse<DepartureSmall[]>> {
            const params: { [x: string]: any } = {};
            if (filters.page) params['page'] = filters.page;
            if (filters.direction) params['direction'] = filters.direction.join(',');
            if (filters.search) params['search'] = filters.search;
            if (filters.customer) params['customer'] = filters.customer;
            if (filters.concierge) params['concierge'] = filters.concierge;
            if (filters.logistician) params['logistician'] = filters.logistician;
            if (filters.deliveryStatuses) params['delivery_status'] = filters.deliveryStatuses.join(',');
            if (filters.departureStatuses) params['departure_status'] = filters.departureStatuses.join(',');
            if (filters.deliveryTypes) params['delivery_type'] = filters.deliveryTypes.join(',');
            if (filters.leadIds?.length) params['lead_ids'] = filters.leadIds.join(',');
            if (filters.startDate) params['start_date'] = filters.startDate;
            if (filters.stopDate) params['stop_date'] = filters.stopDate;

            const path = basePath + "/api/admin/logistic/departure" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DepartureSmall[]>([], error);
            }
        },

        /** Функция получения фильтров */
        async getFilters(): Promise<ApiResponse<DepartureFilters>> {
            const path = basePath + "/api/admin/logistic/departure/filters"

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DepartureFilters>(getEmptyDepartureFilters(), error);
            }
        },

        /** Функция получения локализации */
        async getLocalizate(): Promise<ApiResponse<DepartureLocalizate>> {
            const path = basePath + "/api/admin/logistic/departure/statuses"

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DepartureLocalizate>(getEmptyDepartureLocalizate(), error);
            }
        },

        /** Функция получения отправления */
        async getDepartureItem(id: number): Promise<ApiResponse<DepartureFull>> {
            const path = basePath + `/api/admin/logistic/departure/${id}`

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DepartureFull>(getEmptyDepartureFull(), error);
            }
        },

        /** Обновление отправления */
        async updateDeparture(id: number, params: DepartureUpdateParams): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/logistic/departure/${id}`;

            try {
                return await fetch(path, {
                    method: "PUT",
                    body: JSON.stringify(params),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        /** Работа с проблемами доставки */
        async updateDepartureProblem(id: number, params: DepartureUpdateProblem): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/logistic/departure/${id}/problems`;

            try {
                return await fetch(path, {
                    method: "PUT",
                    body: JSON.stringify(params),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        /** Добавить сделки в отправление */
        async addLeadDeparture(id: number, leadIds: number[]): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/logistic/departure/${id}/leads`;

            try {
                return await fetch(path, {
                    method: "PUT",
                    body: JSON.stringify({lead_ids: leadIds}),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        /** Открепить сделки в отправление */
        async removeLeadDeparture(id: number, leadIds: number[]): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/logistic/departure/${id}/leads`;

            try {
                return await fetch(path, {
                    method: "DELETE",
                    body: JSON.stringify({lead_ids: leadIds}),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        /** Удалить отправление */
        async deleteDeparture(id: number): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/logistic/departure/${id}`;

            try {
                return await fetch(path, {
                    method: "DELETE",
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

        /** Создать отправление */
        async createDeparture(leadIds: string | number | any[], logisticianId: number, note: string): Promise<ApiResponse<{
            id: number
        }>> {
            const path = basePath + `/api/admin/logistic/departure/`;

            try {
                return await fetch(path, {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({lead_ids: leadIds, logistician_id: logisticianId, note,})
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<{ id: number }>({id: 0}, error);
            }
        },


    },

    notifications: {
        /** Функция получения уведомлений (getNotifications) */
        async getNotifications(type: string): Promise<ApiResponse<AppNotification[]>> {
            const params: { [x: string]: any } = {};
            params['type'] = type;
            // if (filters.page) params['page'] = filters.page;
            // if (filters.direction) params['direction'] = filters.direction.join(',');
            // if (filters.search) params['search'] = filters.search;
            // if (filters.customer) params['customer'] = filters.customer;
            // if (filters.concierge) params['concierge'] = filters.concierge;
            // if (filters.logistician) params['logistician'] = filters.logistician;
            // if (filters.deliveryStatuses) params['delivery_status'] = filters.deliveryStatuses.join(',');
            // if (filters.departureStatuses) params['departure_status'] = filters.departureStatuses.join(',');
            // if (filters.deliveryTypes) params['delivery_type'] = filters.deliveryTypes.join(',');
            // if (filters.leadIds?.length) params['lead_ids'] = filters.leadIds.join(',');
            // if (filters.startDate) params['start_date'] = filters.startDate;
            // if (filters.stopDate) params['stop_date'] = filters.stopDate;

            const path = basePath + "/api/admin/notifications" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<AppNotification[]>([], error);
            }
        },

        /** Функция получения уведомлений (getNotifications) */
        async getNotificationsFilters(): Promise<ApiResponse<AppNotificationFilters>> {
            const path = basePath + "/api/admin/notifications/filters"

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<AppNotificationFilters>(getEmptyAppNotificationFilters(), error);
            }
        },

        /** Удалить отправление */
        async deleteNotifications(ids: number[]): Promise<ApiResponse<Empty>> {
            const path = basePath + `/api/admin/notifications`;

            try {
                return await fetch(path, {
                    method: "DELETE",
                    headers: headers,
                    body: JSON.stringify({ids, all: ids.length == 0})
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },

    },

    bonusType: {
        async getBonusType({page}: { page: number }): Promise<ApiResponse<BonusType[]>> {
            const params: { [x: string]: any } = {};
            params['page'] = page;

            const path = basePath + "/api/bonus-type" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<BonusType[]>(undefined, error);
            }
        },
        async createBonusType({payload,}: { payload: BonusType, }): Promise<ApiResponse<BonusType>> {

            const path = basePath + "/api/bonus-type";

            try {
                return await fetch(path, {
                    method: "POST",
                    body: JSON.stringify(snakeCaseDeep(payload)),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<BonusType>(undefined, error);
            }
        },
        async updateBonusType({id, payload,}: { id: number, payload: BonusType, }): Promise<ApiResponse<BonusType>> {
            const params: { [x: string]: any } = payload;

            params["id"] = id

            const path = basePath + "/api/bonus-type/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<BonusType>(undefined, error);
            }
        },
        async deleteBonusType({id,}: { id: number, }): Promise<ApiResponse<BonusType>> {
            const path = basePath + "/api/bonus-type/{id}".replace('{id}', `${id}`);

            try {
                return await fetch(path, {method: "DELETE", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<BonusType>(undefined, error);
            }
        },

    },
    bonus: {
        async getBonuses({
                             page,
                             search,
                             expired,
                             start_date,
                             stop_date,
                             type_id,
                             transaction_type,
                             total_bonus_max,
                             total_bonus_min,
                             per_page,
                         }: {
            page: number,
            search?: string,
            type_id?: string,
            start_date?: string,
            stop_date?: string,
            expired?: boolean,
            transaction_type?: 'receipt' | 'write_off',
            total_bonus_min?: string,
            total_bonus_max?: string,
            per_page?: string,
        }): Promise<ApiResponse<AppTransaction[]>> {
            const params: { [x: string]: any } = {};
            if (page) {
                params['page'] = page;
            }
            if (search) {
                params['search'] = search;
            }
            if (expired) {
                params['expired'] = expired;
            }
            if (start_date) {
                params['start_date'] = start_date;
            }
            if (stop_date) {
                params['stop_date'] = stop_date;
            }
            if (type_id) {
                params['type_id'] = type_id;
            }
            if (transaction_type) {
                params['transaction_type'] = transaction_type;
            }
            if (total_bonus_min) {
                params['total_bonus_min'] = total_bonus_min;
            }
            if (total_bonus_max) {
                params['total_bonus_max'] = total_bonus_max;
            }
            if (per_page) {
                params['per_page'] = per_page;
            }

            const path = basePath + "/api/bonuses" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<AppTransaction[]>([], error);
            }
        },

        async createBonus(payload: {
            bonus_type_id: number,
            client_ids: any[],
            receipt?: number,
            write_off?: number,
            reason: string,
            success_lead_counter: number
        }): Promise<ApiResponse<BonusType>> {

            const path = basePath + "/api/bonuses";

            try {
                return await fetch(path, {
                    method: "POST",
                    body: JSON.stringify(payload),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<BonusType>(undefined, error);
            }
        },

        async nullingBonus(payload: { client_ids: any[] }): Promise<ApiResponse<Empty>> {

            const path = basePath + "/api/bonuses/nulling";

            try {
                return await fetch(path, {
                    method: "POST",
                    body: JSON.stringify(payload),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(getEmptyEmpty(), r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(getEmptyEmpty(), error);
            }
        },
        async deleteTransaction(payload: { transaction_ids: number[] }): Promise<ApiResponse<Empty>> {

            const path = basePath + "/api/bonuses";

            try {
                return await fetch(path, {
                    method: "DELETE",
                    body: JSON.stringify(payload),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
    },

    departureHdCouriers: {
        async getCourierHdDeparture({name, page, perPage}: {
            name?: string,
            page: number
            perPage?: number
        }): Promise<ApiResponse<CourierHdDeparture[]>> {
            const params: { [x: string]: any } = {};
            params['page'] = page;
            if (name) {
                params['name'] = name;
            }
            if (perPage) {
                params['per_page'] = perPage;
            }

            const path = basePath + "/api/admin/logistic/handelivery/courier" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse([], r[1]));
            } catch (error) {
                return new ApiResponse<CourierHdDeparture[]>([], error);
            }
        },

        async getCourierHdDepartureById({id,}: { id: number, }): Promise<ApiResponse<CourierHdDeparture>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/logistic/handelivery/courier/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<CourierHdDeparture>(undefined, error);
            }
        },

        async createCourierHdDeparture({payload,}: {
            payload: CourierHdDeparture,
        }): Promise<ApiResponse<CourierHdDeparture>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/logistic/handelivery/courier";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(getEmptyCourierHdDeparture(), r[1]));
            } catch (error) {
                return new ApiResponse<CourierHdDeparture>(getEmptyCourierHdDeparture(), error);
            }
        },

        async updateCourierHdDeparture({payload, id}: {
            payload: CourierHdDeparture,
            id: number
        }): Promise<ApiResponse<CourierHdDeparture>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/logistic/handelivery/courier/{id}".replaceAll('{id}', `${id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(getEmptyCourierHdDeparture(), r[1]));
            } catch (error) {
                return new ApiResponse<CourierHdDeparture>(getEmptyCourierHdDeparture(), error);
            }
        },

        async deleteCourierHdDeparture({id}: { id: number }): Promise<ApiResponse<CourierHdDeparture>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/logistic/handelivery/courier/{id}".replaceAll('{id}', `${id}`);

            try {
                return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(getEmptyCourierHdDeparture(), r[1]));
            } catch (error) {
                return new ApiResponse<CourierHdDeparture>(getEmptyCourierHdDeparture(), error);
            }
        },

        async getDepartureHdRegistryFilters(): Promise<ApiResponse<HdDepartureItemsFilters>> {
            const path = basePath + "/api/admin/logistic/handelivery/registry/filters";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<HdDepartureItemsFilters>(getEmptyHdDepartureItemsFilters(), error);
            }
        },
        async getDepartureHdRegistry({
                                         aed_min,
                                         aed_max,
                                         eur_min,
                                         eur_max,
                                         start_refund_deadline,
                                         stop_refund_deadline,
                                         start_buying_date,
                                         stop_buying_date,
                                         priority,
                                         lead_id,
                                         lead_status,
                                         customer_id,
                                         page,
                                         per_page,
                                     }: {
            aed_min?: number,
            aed_max?: number,
            eur_min?: number,
            eur_max?: number,
            start_refund_deadline?: string,
            stop_refund_deadline?: string,
            start_buying_date?: string,
            stop_buying_date?: string,
            priority?: string,
            lead_id?: number,
            lead_status?: string,
            customer_id?: number,
            page: number,
            per_page: number,
        }): Promise<ApiResponse<HdDepartureItems[]>> {
            const params: { [x: string]: any } = {};
            params['page'] = page;

            if (aed_min) params['aed_min'] = aed_min;
            if (aed_max) params['aed_max'] = aed_max;
            if (eur_min) params['eur_min'] = eur_min;
            if (eur_max) params['eur_max'] = eur_max;
            if (start_refund_deadline) params['start_refund_deadline'] = start_refund_deadline;
            if (stop_refund_deadline) params['stop_refund_deadline'] = stop_refund_deadline;
            if (start_buying_date) params['start_buying_date'] = start_buying_date;
            if (stop_buying_date) params['stop_buying_date'] = stop_buying_date;
            if (priority) params['priority'] = priority;
            if (lead_id) params['lead_id'] = lead_id;
            if (lead_status) params['lead_status'] = lead_status;
            if (customer_id) params['customer_id'] = customer_id;
            if (per_page) params['per_page'] = per_page;

            const path = basePath + "/api/admin/logistic/handelivery/registry" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<HdDepartureItems[]>(undefined, error);
            }
        },
        async updateDepartureHdRegistry({id, priority}: { id: number, priority: string }): Promise<ApiResponse<Empty>> {
            const path = basePath + "/api/admin/logistic/handelivery/registry/{id}".replaceAll('{id}', `${id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify({priority}), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(getEmptyEmpty(), r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(getEmptyEmpty(), error);
            }
        },
        async downloadDepartureHdRegistry(params: HdDepartureItemsFilterPayload): Promise<ApiResponse<any>> {
            const path = basePath + "/api/admin/logistic/handelivery/registry";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.blob()]))
                    .then((r: any[]) => {
                        return [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(getEmptyEmpty(), r[1]);
                    });
            } catch (error) {
                return new ApiResponse<Empty>(getEmptyEmpty(), error);
            }
        },


    },

    deliveryServices: {
        async getDeliveryServices({page, perPage}: {
            page: number,
            perPage: number
        }): Promise<ApiResponse<DeliveryServices[]>> {
            const params: { [x: string]: any } = {};
            params['page'] = page;
            params['per_page'] = perPage;

            const path = basePath + "/api/admin/logistic/handelivery/delivery_services" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DeliveryServices[]>(undefined, error);
            }
        },
        async createDeliveryServices({payload,}: {
            payload: DeliveryServices,
        }): Promise<ApiResponse<DeliveryServices>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/logistic/handelivery/delivery_services";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DeliveryServices>(undefined, error);
            }
        },

        async updateDeliveryServices({payload,}: {
            payload: DeliveryServices,
        }): Promise<ApiResponse<DeliveryServices>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/logistic/handelivery/delivery_services/{id}".replaceAll('{id}', `${payload.id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DeliveryServices>(undefined, error);
            }
        },

        async deleteDeliveryServices(id: number): Promise<ApiResponse<DeliveryServices>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/logistic/handelivery/delivery_services/{id}".replaceAll('{id}', `${id}`);

            try {
                return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DeliveryServices>(undefined, error);
            }
        },

        async getDeliveryServicesById({id,}: { id: number, }): Promise<ApiResponse<DeliveryServices>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/logistic/handelivery/delivery_services/{id}".replace('{id}', `${id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DeliveryServices>(undefined, error);
            }
        },
    },

    airport: {
        async getAirport({page, perPage}: { page: number, perPage?: number }): Promise<ApiResponse<Airport[]>> {
            const params: { [x: string]: any } = {};
            params['page'] = page;
            if (perPage) params['per_page'] = perPage;

            const path = basePath + "/api/admin/logistic/handelivery/airports" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Airport[]>(undefined, error);
            }
        },
        async createAirport({payload,}: { payload: Airport, }): Promise<ApiResponse<Airport>> {
            const params: { [x: string]: any } = payload;

            const path = basePath + "/api/admin/logistic/handelivery/airports";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Airport>(undefined, error);
            }
        },


        async updateAirport({payload,}: { payload: Airport, }): Promise<ApiResponse<Airport>> {
            const params: { [x: string]: any } = payload;


            const path = basePath + "/api/admin/logistic/handelivery/airports/{id}".replaceAll('{id}', `${payload.id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Airport>(undefined, error);
            }
        },

        async deleteAirport(id: number): Promise<ApiResponse<Airport>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/logistic/handelivery/airports/{id}".replaceAll('{id}', `${id}`);

            try {
                return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Airport>(undefined, error);
            }
        },

        async getAirportById(id: number): Promise<ApiResponse<Airport>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/logistic/handelivery/airports/{id}".replaceAll('{id}', `${id}`);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Airport>(undefined, error);
            }
        },


    },

    departureHd: {
        async getDepartureHd({
                                 page,
                                 per_page,
                                 start_created_at,
                                 stop_created_at,
                                 responsible_search,
                                 min_delivery_cost,
                                 max_delivery_cost,
                                 status,
                                 note_search,
                                 min_aed_sum,
                                 max_aed_sum,
                                 min_eur_sum,
                                 max_eur_sum,
                                 courier_search,
                             }: HdDepartureListFiltersValuesPayload): Promise<ApiResponse<DepartureHd[]>> {
            const params: { [x: string]: any } = {};
            params['page'] = page;

            if (start_created_at) params['start_created_at'] = start_created_at;
            if (stop_created_at) params['stop_created_at'] = stop_created_at;
            if (responsible_search) params['responsible_search'] = responsible_search;
            if (min_delivery_cost) params['min_delivery_cost'] = min_delivery_cost;
            if (max_delivery_cost) params['max_delivery_cost'] = max_delivery_cost;
            if (status) params['status'] = status;
            if (note_search) params['note_search'] = note_search;
            if (min_aed_sum) params['min_aed_sum'] = min_aed_sum;
            if (max_aed_sum) params['max_aed_sum'] = max_aed_sum;
            if (min_eur_sum) params['min_eur_sum'] = min_eur_sum;
            if (max_eur_sum) params['max_eur_sum'] = max_eur_sum;
            if (courier_search) params['courier_search'] = courier_search;
            if (per_page) params['per_page'] = per_page;

            const path = basePath + "/api/admin/logistic/handelivery/courier_departures" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DepartureHd[]>(undefined, error);
            }
        },
        async createDepartureHd({product_ids,}: { product_ids: number[], }): Promise<ApiResponse<DepartureHd>> {
            const path = basePath + "/api/admin/logistic/handelivery/courier_departures";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify({product_ids}), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DepartureHd>(undefined, error);
            }
        },
        async getDepartureHdById({id}: { id: number }): Promise<ApiResponse<DepartureHd>> {
            const path = basePath + "/api/admin/logistic/handelivery/courier_departures/{courier_departure_id}".replace('{courier_departure_id}', `${id}`);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(getEmptyDepartureHd(), r[1]));
            } catch (error) {
                return new ApiResponse<DepartureHd>(getEmptyDepartureHd(), error);
            }
        },
        async updateDepartureHd(payload: {
            courier_departure_id: number,
            updating_data: {
                courier_id: number,
                hand_over_ts: string,
                departure_ts: string,
                arriving_ts: string,
                departure_cost: number,
                note: string,
                airport_id: number,
                actual_courier_phone: string,
                photos: string[],
                deleting_photos: string[],
            },
            products: {
                id: number, photos: string[], hd_status?: string, invoice_flag?: boolean
                deleting_photos: string[],
            }[],
            deleting_photos: string[],
            type: 'planned' | 'uae_hand_over' | 'at_russian_store' | 'processing' | 'processed'
        }): Promise<ApiResponse<DepartureHd>> {

            const path = basePath + "/api/admin/logistic/handelivery/courier_departures/{courier_departure_id}".replace('{courier_departure_id}', `${payload.courier_departure_id}`);

            try {
                return await fetch(path, {
                    method: "POST",
                    body: JSON.stringify(payload),
                    headers: headers
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DepartureHd>(undefined, error);
            }
        },
        async deleteDepartureHd({courier_departure_id,}: {
            courier_departure_id: number
        }): Promise<ApiResponse<DepartureHd>> {
            const path = basePath + "/api/admin/logistic/handelivery/courier_departures/{courier_departure_id}".replace('{courier_departure_id}', `${courier_departure_id}`);

            try {
                return await fetch(path, {method: "DELETE", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DepartureHd>(undefined, error);
            }
        },
        async getDepartureHdFilters(): Promise<ApiResponse<DepartureHdFilters>> {
            const path = basePath + "/api/admin/logistic/handelivery/courier_departures/filters";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DepartureHdFilters>(getEmptyDepartureHdFilters(), error);
            }
        },
        async downloadDepartureHd(params: HdDepartureListFiltersValuesPayload): Promise<ApiResponse<any>> {
            let params2 = params.ids ? { ids: params.ids.join(',') } : params;

            // @ts-ignore
            const path = basePath + "/api/admin/logistic/handelivery/courier_departures/excel" + "?" + new URLSearchParams(params2);

            try {
                return await fetch(`${path}`, { method: "GET", headers: headers })
                    .then(async r => ([r, await r.blob()]))
                    .then((r: any[]) => {
                        return [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(getEmptyEmpty(), r[1]);
                    });
            } catch (error) {
                return new ApiResponse<Empty>(getEmptyEmpty(), error);
            }
        },
    },

    departureHdDeliveryServices: {
        async getHdDepartureDeliveryServicesFilters(): Promise<ApiResponse<HdDepartureDeliveryServicesFilters>> {
            const path = basePath + "/api/admin/logistic/handelivery/delivery_service_departures/filters";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<HdDepartureDeliveryServicesFilters>(getEmptyHdDepartureDeliveryServicesFilters(), error);
            }
        },
        async getHdDepartureOrderListFiles(courier_departure_id: number): Promise<ApiResponse<any>> {
            const path = basePath + `/api/admin/logistic/handelivery/delivery_service_departures/${courier_departure_id}/pdf`;

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.blob()]))
                    .then((r: any[]) => {
                        return [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(getEmptyEmpty(), r[1]);
                    });
            } catch (error) {
                return new ApiResponse<Empty>(getEmptyEmpty(), error);
            }
        },

        async getHdDepartureDeliveryServices(params:
            {
                lead_ids?: string,
                customer_ids?: string,
                lead_status?: string,
                page?: string
                user_priority?: string
            }
            ): Promise<ApiResponse<HdDepartureDeliveryServices2[]>> {

            const path = basePath + "/api/admin/logistic/handelivery/delivery_service_departures" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<HdDepartureDeliveryServices2[]>([], error);
            }
        },
        async getHdDepartureDeliveryServicesById({courier_departure_id,}: {
            courier_departure_id: number,
        }): Promise<ApiResponse<DeliveryServiceDeparture>> {
            const params: { [x: string]: any } = {};

            const path = basePath + "/api/admin/logistic/handelivery/delivery_service_departures/{courier_departure_id}".replace('{courier_departure_id}', `${courier_departure_id}`) + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<DeliveryServiceDeparture>(getEmptyDeliveryServiceDeparture(), error);
            }
        },
        async updateHdDepartureDeliveryServices(payload: {
            courier_departure_id: number,
            updating_data: {
                "logistician_id"?: number,
                "rf_departure_ts"?: string,
                "track_number_rf"?: string,
                "delivery_cost_rf"?: number | null | undefined,
                "invoice_data"?: string | null | undefined,
                "note"?: string,
                "user_priority"?: string,
                "address"?: string,
                deleting_photos?: string[],
            },
            products: {
                id: number,
                hd_status?: string,
                deleting_photos?: string[],
                photos: string[],
            }[],
            deleting_photos?: string[],
            type: 'saving' | 'sending'
        }): Promise<ApiResponse<HdDepartureDeliveryServices2>> {
            const params: { [x: string]: any } = payload;
            
            const path = basePath + "/api/admin/logistic/handelivery/delivery_service_departures/{courier_departure_id}".replace('{courier_departure_id}', `${payload.courier_departure_id}`);

            try {
                return await fetch(path, {
                    method: "POST",
                    body: JSON.stringify({...params}),
                    // headers: {...headers, 'Content-Type': 'multipart/form-data',}
                    headers: {...headers}
                })
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<HdDepartureDeliveryServices2>(undefined, error);
            }
        },
        async deleteHdDepartureDeliveryServices({courier_departure_id,}: {
            courier_departure_id: number,
        }): Promise<ApiResponse<Empty>> {
            const params: { [x: string]: any } = {};

            params["courier_departure_id"] = courier_departure_id

            const path = basePath + "/api/admin/logistic/handelivery/delivery_service_departures/{courier_departure_id}".replace('{courier_departure_id}', `${courier_departure_id}`);

            try {
                return await fetch(path, {method: "DELETE", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
        async receiveHdDepartureDeliveryServices({courier_departure_id, products}: {
            courier_departure_id: number,
            products: {
                hd_status: string
                id: number
                photos: any[]
            }[]
        }): Promise<ApiResponse<Empty>> {
            const path = basePath + "/api/admin/logistic/handelivery/delivery_service_departures/received_by_client/{courier_departure_id}".replace('{courier_departure_id}', `${courier_departure_id}`);

            try {
                return await fetch(path, {method: "PUT", body: JSON.stringify({products}), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
    },
    departureHdAnalitics: {
        async getHdAnaliticsFilters(): Promise<ApiResponse<HdAnaliticsFilters>> {
            const path = basePath + "/api/admin/logistic/handelivery/analytics/filters";

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<HdAnaliticsFilters>(getEmptyHdAnaliticsFilters(), error);
            }
        },
        async getHdAnaliticsList({ page, leadId, perPage, start_created_at, stop_created_at, start_uae_hand_over_ts, stop_uae_hand_over_ts }: {
            perPage?: number,
            page: number,
            leadId?: string,
            start_created_at?: string,
            stop_created_at?: string,
            start_uae_hand_over_ts?: string,
            stop_uae_hand_over_ts?: string,
        }): Promise<ApiResponse<HdAnalitics[]>> {
            const params: { [x: string]: any } = {};
            params['page'] = page;
            if (perPage){
                params['per_page'] = perPage;
            }
            if (leadId)
                params['lead_id'] = leadId;
            if (start_created_at)
                params['start_created_at'] = start_created_at
            if (stop_created_at)
                params['stop_created_at'] = stop_created_at
            if (start_uae_hand_over_ts)
                params['start_uae_hand_over_ts'] = start_uae_hand_over_ts
            if (stop_uae_hand_over_ts)
                params['stop_uae_hand_over_ts'] = stop_uae_hand_over_ts
            const path = basePath + "/api/admin/logistic/handelivery/analytics" + "?" + new URLSearchParams(params);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<HdAnalitics[]>([], error);
            }
        },

        async downloadDepartureHdAnalitics(params: HdDepartureListAnaliticsFiltersPayload): Promise<ApiResponse<any>> {
            const path = basePath + "/api/admin/logistic/handelivery/analytics";

            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(params), headers: headers})
                    .then(async r => ([r, await r.blob()]))
                    .then((r: any[]) => {
                        return [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(getEmptyEmpty(), r[1]);
                    });
            } catch (error) {
                return new ApiResponse<Empty>(getEmptyEmpty(), error);
            }
        },

       
    },

    customerRefundForm: {
        async getRefundFormData(props: { id: string}): Promise<ApiResponse<RefundFormData>> {
            const path = basePath + "/api/client_refund_form/{id}".replace('{id}', `${props.id}`);

            try {
                return await fetch(path, {method: "GET", headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1], undefined, true) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<RefundFormData>(getEmptyRefundFormData(), error);
            }
        },
        async saveRefundFormData(props: { id: string, data: RefundFormDataUpdate }): Promise<ApiResponse<Empty>> {
            const path = basePath + "/api/client_refund_form/{id}".replace('{id}', `${props.id}`);


            try {
                return await fetch(path, {method: "POST", body: JSON.stringify(props.data), headers: headers})
                    .then(async r => ([r, await r.json()]))
                    .then(r => [200, 201].includes(r[0].status) ? new ApiResponse(r[1]) : new ApiResponse(undefined, r[1]));
            } catch (error) {
                return new ApiResponse<Empty>(undefined, error);
            }
        },
    }
}

